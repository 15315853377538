import { RichText } from "prismic-reactjs";
import React, { useEffect, useState } from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import "../../../styles/components/HowItWorks/Process/style.scss"

const Process = ({ heading, processes }) => {
  const [isSmallerScreen, setIsSmallerScreen] = useState(false);
  useEffect(() => {
    if (window) {
      if (window.innerWidth <= 526) {
        setIsSmallerScreen(true);
      }
      window.addEventListener("resize", onResize);

      return () => {
        window.removeEventListener("resize", onResize);
      };
    }
  }, []);

  const onResize = (e) => {
    if (e.currentTarget.innerWidth <= 526) {
      setIsSmallerScreen(true);
    } else if (e.currentTarget.innerWidth > 526 && isSmallerScreen) {
      setIsSmallerScreen(false);
    }
  };

  if (isSmallerScreen) {
    return (
      <>
        <Row className="content-margin mw-1440 mx-auto">
          <Col sm={12}>
            <div className="banner">
              <h3 className="second-title">{heading}</h3>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <Image src={processes && processes[0].hiw_process_image.url} />
                <RichText render={processes && processes[0].hiw_process_description} />
              </div>
              <div>
                <Image src="/assets/images/arrow2.png" className="d-block mx-auto" />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <Image src={processes && processes[1].hiw_process_image.url} />
                <RichText render={processes && processes[1].hiw_process_description} />
              </div>
              <div>
                <Image src="/assets/images/arrow2.png" className="d-block mx-auto" />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <Image src={processes && processes[2].hiw_process_image.url} />
                <RichText render={processes && processes[2].hiw_process_description} />
              </div>
              <div>
                <Image src="/assets/images/arrow2.png" className="d-block mx-auto" />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <Image src={processes && processes[3].hiw_process_image.url} />
                <RichText render={processes && processes[3].hiw_process_description} />
              </div>
              <div>
                <Image src="/assets/images/arrow2.png" className="d-block mx-auto" />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <Image src={processes && processes[4].hiw_process_image.url} />
                <RichText render={processes && processes[4].hiw_process_description} />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <hr className="process-section-end" />
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row className="justify-content-center align-items-center mw-1440 mx-auto">
          <Col sm={12} className="mb-lg-4 mb-1">
            <div>
              <h3 className="second-title">{heading}</h3>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <RichText render={processes && processes[0].hiw_process_description} />
                <Image
                  src={processes && processes[0].hiw_process_image.url}
                />
              </div>
              <div className="set-process-arrow">
                <Image src="/assets/images/Arrow 1.png" />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <RichText render={processes && processes[1].hiw_process_description} />
                <Image
                  src={processes && processes[1].hiw_process_image.url}
                />
              </div>
              <div className="set-process-arrow">
                <Image src="/assets/images/Arrow 1.png" />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <RichText render={processes && processes[2].hiw_process_description} />
                <Image
                  src={processes && processes[2].hiw_process_image.url}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <Image
                  src={processes && processes[3].hiw_process_image.url}
                />
                <RichText render={processes && processes[3].hiw_process_description} />
              </div>
              <div className="set-process-arrow last_arrow">
                <Image src="/assets/images/Arrow 1.png" />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="process-container">
              <div className="process">
                <Image
                  src={processes && processes[4].hiw_process_image.url}
                />
                  <RichText render={processes && processes[4].hiw_process_description} />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <hr className="process-section-end" />
          </Col>
        </Row>
      </>
    );
  }
};

export default Process;
