import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Banner from "../components/HowItWorks/Banner";
import Process from "../components/HowItWorks/Process";
import { client as prismicClient } from "../libs/prismic";
import PreFooter from "../components/PreFooter";
import Loader from "../components/Loader";

const HowItWorks = () => {
  const [howItWorkDoc, setHowItWork] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("how_it_works");
      setHowItWork(response);
    }
    fetchData();
  }, []);
  return howItWorkDoc ? (
    <Layout>
      <Banner content={howItWorkDoc} />
      <Process
        heading={
          howItWorkDoc && howItWorkDoc?.data?.hiw_process_heading?.[0]?.text
        }
        processes={howItWorkDoc && howItWorkDoc?.data?.processes}
      />
      <PreFooter
        className="invest-pre-footer"
        prefooterText={howItWorkDoc?.data?.prefooter}
        buttonText={howItWorkDoc?.data?.prefooter_button_text?.[0]?.text}
        buttonLink={howItWorkDoc?.data?.prefooter_button_link?.url}
      />
    </Layout>
  ) : (
    <Loader />
  );
};

export default HowItWorks;
