import { RichText } from "prismic-reactjs";
import { connect } from "react-redux";
import React from "react";
import { Button } from "react-bootstrap";
import { signup } from "../../utils/auth";
import {
  showAlert,
} from "../../store/reducers/alert";
import { moduleTypes } from "../../store/type";
import "../../styles/components/PreFooter/style.scss";
import { navigate } from "gatsby";

const PreFooter = ({
  prefooterText,
  buttonText,
  buttonLink,
  className,
  isLoggedIn,
}) => {
  const handleNavigate = () => {
    if (buttonLink) {
      // this will break gatsby+auth0
      window.location.href = buttonLink;
      return;
    }
    if (!isLoggedIn) {
      navigate('/signup');
      return;
    }

    // we are guessing that this button wants us to 
    // go here.
    navigate('/investor-qualification/');
  };

  return (
    <>
      <div className={className}>
        <div className="rich-text">
          <RichText render={prefooterText} />
          <Button
            variant="dark"
            className="get-started-btn"
            onClick={handleNavigate}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </>
  );
};


const mapStateToProps = (state) => ({
  activeStep: state[moduleTypes.INVESTOR_QUALIFICATION].activeStep,
  isLoggedIn: state[moduleTypes.APP].isLoggedIn,
})

function mapDispatchToProps (dispatch) {
  return {
    showAlert: (...rest) => {
      dispatch(showAlert(...rest))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreFooter);
