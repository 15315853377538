import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { isMobile } from "../../../utils";
import "../../../styles/components/HowItWorks/Banner/style.scss"

const Banner = ({ content }) => {

  const bannerText = content?.data?.section_1;
  const showImage = content?.data?.hiw_show_image;
  const showImageMobile = content?.data?.hiw_show_image_mobile;
  const imgSRC = content?.data?.hiw_image?.url;
  const imageWidth = content?.data?.hiw_image?.dimensions?.width;
  const imageHeight = content?.data?.hiw_image?.dimensions?.height;

  if (!content) {
    return null;
  }
  return (
    <>
      <Row className="mx-auto mw-1440 how_it_works">
        <Col md={6} className="pr-20">
          <div className="banner1 rich-text">
            <RichText render={bannerText} />
          </div>
        </Col>
        {!isMobile() && showImage && (
          <Col className="banner-img pl-20" md={6}>
            <Image
              src={imgSRC}
              alt=""
              fluid
              width={imageWidth}
              height={imageHeight}
            />
          </Col>
        )}
        {isMobile() && showImageMobile && (
          <Col className="banner-img" md={6}>
            <Image
              src={imgSRC}
              alt=""
              fluid
              width={imageWidth}
              height={imageHeight}
            />
          </Col>
        )}
        <Col md={12}>
          <hr className="section-end" />
        </Col>
      </Row>
    </>
  );
};

export default Banner;
